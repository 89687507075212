// Import external modules and components
import React from "react";
import axios from "axios";
import emailjs from "@emailjs/browser";
import { Link, useParams, useLocation, Navigate } from "react-router-dom";
import { ScrollToTopOnMount, CheckoutForm } from "./Bases";
// import NumberFormat from "react-number-format";
import $ from 'jquery';

// Import image files
import tick from "./static/tick.png";
import close from "./static/failed.png";
import union from "./static/Union.png";
import whitebg from './static/paymentimgs.png';
import paystack from './static/paystack.png';
// import cyberstickbent from './static/cyberstickbent.png';
import { Header } from "./Bases";
import { CYBERSTICK_BACKEND } from ".";



// Return the Payment Successful popup
function PaymentSuccessful() {
    return (
        <div className="card w-75 validation-card  pb-30px mx-auto hidden" id='success_overlay'>
            <div className="card-body text-center">
                <Link to='/'>
                    <div className="text-right">    
                    <span className="close-card">&times;</span></div>    
                </Link>
                <img src={tick} className="validation-img" alt="" />
                <h3 className="p-3">Order Successful</h3>
                <Link to='/'>
                    <button className='checkout-btn validation-btn mt-30px'>Go back to homepage</button>
                </Link>
            </div>
        </div>
    )
}

// Return the Payment Failed popup
function PaymentFailed() {
    return (
        <div className="card w-75 validation-card pb-30px mx-auto hidden" id='failed_overlay'>
            <div className="card-body text-center">
                <div className="text-right"><span className="close-card"
                onClick={
                    (e) => {if (e.target.parentElement.parentElement.parentElement.id === 'overlay')
                    e.target.parentElement.parentElement.parentElement.classList.add('hidden')
                    else e.target.parentElement.parentElement.classList.add('hidden')
                }}>&times;</span></div>    
                <div className="validation-img-wrapper d-flex justify-content-center
                 position-relative">
                    <img src={union} className="validation-img-union position-absolute" alt="" />
                    <img src={close} className="validation-img-close position-absolute" alt="" />
                </div>
                <h3 className="p-3">Payment Failed</h3>
                <button className='checkout-btn validation-btn mt-30px'
                // onClick={() => {window.location.href = 'http://cyberstck.io/checkout'}}>Retry
                onClick={() => {window.location.href = 'http://localhost:3000/checkout'}}>Retry
                </button>
            </div>
        </div>
    )
}



// Make Payment through paystack
class Payment extends React.Component {
    constructor(props) {
        sessionStorage.removeItem('PIP');    
        $('#spinner').removeClass('hidden');        
        super(props)
        const state_initials = props.location.state
        // console.log(state_initials)
        this.state = {
            ...state_initials,
            email: '',
            order_id: '',
            // paymentmethod: '',
        }
    }

    makePayment = (e) => {
        // Initiates paystack popup
        const paymentoption = e.target.paymentoption.value;
        if (e.target.terms.checked && paymentoption !== '') {
            axios
            .get(`${CYBERSTICK_BACKEND}/cyberstick/orders/${sessionStorage.getItem('order_token')}`)    
            .then((res) => {
                this.setState({email: res.data.email, order_id: res.data.id})
                sessionStorage.setItem('email', res.data.email)
                
                // this.setState({paymentmethod: paymentoption})

                const PaystackPop = window.customLib['PaystackPop']
                window.sessionStorage.setItem('order_id', this.state.order_id)
                let total_price = (Number(this.state.price) + Number(this.state.shipping)) * 100
                let handler = PaystackPop.setup({
                    key: 'pk_live_c15fc0bb4c1a79798e557c2d6fef094d51747766',
                    // key: 'pk_test_e7c9e314a1fedde2334df88d284253b5dd17e059',
                    email: sessionStorage.getItem('email'),
                    amount: total_price,
                    // amount: 1 * 100,
                    ref: sessionStorage.getItem('order_token'),
                    onClose: function(){
                        alert('Payment cancelled')
                        axios.put(`${CYBERSTICK_BACKEND}/cyberstick/orders/${sessionStorage.getItem('order_token')}`, {
                                status: 1,
                        })
                    },
                    onerror: function(err) {
                        console.log(err)
                    },
                    callback: function(response) {
                        $('#spinner').removeClass('hidden');
                        axios.put(`${CYBERSTICK_BACKEND}/cyberstick/orders/${response.reference}`, {
                                status: 2,
                        })
                        .then((res) => {
                            // axios.post(`${EMAIL_BACKEND}/cy-mail`, { 
                            //     full_name: sessionStorage.getItem('customer'),
                            //     to_email: sessionStorage.getItem('email'),
                            //     email_template: "template2",
                            // },  {headers: {'Authorization': "919c02f004339822ecb967cc526977b528f7ba55",  'Content-Type': `multipart/form-data`}})    
                            emailjs.send('service_9d45rmc', 'template_axnpgry', {
                                'to_name': sessionStorage.getItem('customer'),
                                'reply_to': sessionStorage.getItem('email'),
                            }, 'rmJsklIWliSqgNSbG')
                            .then(res => {
                                // alert('Order successful, Email confirmation sent')
                                $('body').addClass('no-scroll');
                                $('#success_overlay').removeClass('hidden');
                                $('#spinner').addClass('hidden');
                                emailjs.send('service_9d45rmc', 'template_b1uxsif', {
                                    'customer_name': sessionStorage.getItem('customer'),
                                    'customer_address': sessionStorage.getItem('address'),
                                    'customer_email': sessionStorage.getItem('email'),
                                    'customer_number': sessionStorage.getItem('phone_number'),
                                    'customer_state': sessionStorage.getItem('state'),
                                    'amount': sessionStorage.getItem('amount'),
                                    'price_paid': sessionStorage.getItem('price'),
                                    'advert_mode': sessionStorage.getItem('advert_mode'),
                                    'paystack_id': sessionStorage.getItem('order_token'),
                                }, 'rmJsklIWliSqgNSbG')
                                .then((res) => {
                                    window.sessionStorage.clear()    
                                    console.log('success')
                                }).catch((res) => {console.log('failed')})
                            }
                            )
                        })
                        .catch((err) => {
                            console.log(err.data)
                            $('body').addClass('no-scroll');
                            $('#failed_overlay').removeClass('hidden');
                            $('#spinner').addClass('hidden');
                        })
                    }
                })
                handler.openIframe();
            })
            .catch((err) => {alert(err.message)})
        } 
    }    

    componentDidMount() {
        setTimeout(() => sessionStorage.removeItem('new_customer'), 500000)
        $('#spinner').addClass('hidden');
        $('body').removeClass('no-scroll');
    }

    componentWillUnmount() {
        $('#spinner').removeClass('hidden');
    }
    
    render() {
        if (!sessionStorage.getItem('new_customer')) return <Navigate to='/shipping' />
        if (!this.state.total_price && !this.state.price) return <Navigate to='/checkout' />
        
        return (
            <div>
                <ScrollToTopOnMount />
                <Header />

                <div className="container-fluid">
                    <section className='row checkout-section-1
                    justify-content-around align-items-start g-0px'>
                        <div className="col-md-6 col-12">
                            <div className="py-5 pb-30px text-sm-left text-center">
                                <h2 className="fw-400 text-white">How Do You Want To Pay?</h2> 
                                <p className="text-muted">Payment Methods</p>
                            </div>
                            <div className="position-relative mt--20px mb-30px">
                                <img className="whitebg postion-absolute" src={whitebg} alt="" />
                                <img className="paystack position-absolute" src={paystack} alt="" />
                            </div>
                            <form onSubmit={(e) => {e.preventDefault(); this.makePayment(e);}}>
                                <div className="custom-control custom-radio">
                                    <input className="custom-control-input" type='radio' id='debit_card' name='paymentoption' value='debit card' selected />
                                    <label className="custom-control-label fs-1p2em text-white" htmlFor='debit_card'>Pay with debit/credit card</label>
                                </div>
                                <p className="text-muted mt-1">Make payment using your debit and credit cards</p>
                                <div className="custom-control custom-checkbox mt-5">
                                    <input className="custom-control-input" type='checkbox'
                                    id='terms' name='terms' />    
                                    <label className="custom-control-label fs-1em " htmlFor='terms'>
                                        I have read and agreed to the website <Link to='/TermsandConditions'>terms and conditions</Link>
                                    </label>
                                </div>
                                <button className='checkout-btn mt-80px' type='submit'
                                value='Checkout'>
                                    Make Payment
                                </button>
                            </form>
                        </div>
                        <div className="mt-30px col-12 col-md-4">
                            <CheckoutForm header="your order total" price={this.state.price}
                                shipping={this.state.shipping} tax={this.state.tax}
                                total_price={this.state.total_price} />
                        </div>
                    </section>
                </div>
            </div>
        )
    }    
}

// Wrap class components in functional components to be able to use React Hooks
const Payment_wrapper = () => (
    <Payment params={useParams()} location={useLocation()} />
);

export {
     Payment_wrapper as Payment,
    PaymentFailed, PaymentSuccessful }