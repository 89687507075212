// Import external modules and components
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Import custom modules, functions, variables and components
import { Footer, Faq } from './Bases';
import Home from './Home';
import Checkout from './Checkout';
import Shipping from './Shipping';
import { Payment, PaymentSuccessful, PaymentFailed } from './Payment';
import { loadProgressBar } from 'axios-progress-bar';
import { TermsAndCondition } from './officialDocs/termsAndCondition';

// Import external styles
import 'axios-progress-bar/dist/nprogress.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { PrivacyPolicy } from './officialDocs/privacyPolicy';
import { NotFound } from './notFound';
import Order from './Order';
import OrderDetails from './OrderDetails';

loadProgressBar()


export const EMAIL_BACKEND = "https://email-backend.nativetalkapp.com/flsk"
export const CYBERSTICK_BACKEND = "https://dashboard.cyberstick.io"


// Control routing of app
class App extends React.Component {
  reveal() {
    let reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
      let windowHeight = window.innerHeight;
      let elementTop = reveals[i].getBoundingClientRect().top;
      let elementVisible = 100;

      if (elementTop < windowHeight - elementVisible)
        reveals[i].classList.add('active');
      else
      reveals[i].classList.remove('active');
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.reveal);
  }

  render() {
    return (
      <Router>
        <div>
          <PaymentSuccessful />
          <PaymentFailed />
          <Routes>
            <Route exact path='/' element={<Home />} />
            <Route exact path='/Order' element={<Order />} />
            <Route exact path='/OrderDetails' element={<OrderDetails />} />
            <Route exact path='/faq' element={<Faq />} />
            <Route exact path='/checkout'  element={<Checkout />} />
            <Route exact path='/shipping' element={<Shipping />} />
            <Route exact path='/payment' element={<Payment />} />
            <Route exact path='/TermsandConditions' element={<TermsAndCondition />} />
            <Route exact path='/PrivacyPolicy' element={<PrivacyPolicy />} />

            <Route path="*" element={<NotFound />} />
          </Routes>
          <div className="border rounded" id="spinner"></div> {/** shows spinner when api requests are in progress */}
          <Footer />
        </div>
      </Router>
    )
  }
}

// Render app in the element with id 'root' in the index.html page
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
